import {
    Box,
    Dialog,
    DialogContent, DialogTitle,
    LinearProgress,
    List,
    ListItem,
    ListItemText
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import MultEmpContext from "../../context/MultEmpContext";
import api from "../../services/api";

const Index = (props) => {
    const [Open, setOpen] = useState(false);
    const [Empresas, setEmpresas] = useState(null);
    const [EmpLoad, setEmpLoad] = useState(true);

    const { OK } = useContext(MultEmpContext);

    const configHearders = {
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
            "Content-Type": "application/json"
        },
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(!props.open) {
            setEmpresas(JSON.parse(sessionStorage.getItem("@Empresas")))
            setOpen(true);
            setEmpLoad(false);
        }
    }, []);

    return (
        <>
        <Dialog open={Open} onClose={handleClose} keepMounted={true}>
            <DialogTitle>Selecione qual empresa você gostaria de acessar:</DialogTitle>
            <DialogContent>
            { EmpLoad ? 
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            : null }
            <List>
                { Empresas ? (
                    <>
                        {Empresas.map((Reg) => (
                            <ListItem>
                                <ListItemText
                                    primary={Reg.razao_social}
                                    secondary={cpfCnpj(Reg.cnpj)}
                                    key={Reg.id}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setID(Reg)}
                                />
                            </ListItem>
                        ))}
                    </>
                ) : null }
            </List>
            </DialogContent>
        </Dialog>
        </>
    );

    async function setID(obj){
        await api.get(
            `contratos/empresa?id_rurauth=${obj.id}`, configHearders
        ).then((response) => {
            if(response.data[0]) {
                obj.id_rurauth = response.data[0].id
            }else{
                obj.id_rurauth = response.data.id
            }

            SelEmp(obj)
        })
        .catch((error) => {
            console.log(error)
        });
    };

    function SelEmp(obj){
        sessionStorage.setItem("@IdEmpresa", parseInt(obj.id));
        sessionStorage.setItem("@CnpjEmpresa", obj.cnpj);
        sessionStorage.setItem("@razao_social", obj.razao_social);
        sessionStorage.setItem("@id_rurauth", obj.id_rurauth)

        setOpen(false);
        OK();
    }

    function cpfCnpj(value) {
        if (value !== "" && value !== undefined) {
            const cnpjCpf = value.replace(/\D/g, "");
    
            if (cnpjCpf.length === 11) {
                return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
            }
    
            return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
        }
    }
};

export default Index;
