import { createContext, useEffect, useState } from "react";
import api from "../services/api";
import auth from "../services/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [erro, setErro] = useState("");
  const [errorServer, seterrorServer] = useState("");
  const [loanding, setLoanding] = useState(false);

  const loadingStoreData = () => {
    const storageUser = sessionStorage.getItem("@Auth:nome");
    const storageToken = sessionStorage.getItem("@Auth:token");

    if (storageUser && storageToken) {
      setUser(storageUser);
    }
  };

  async function signIn(data){
    setLoanding(true);
    try {
      const response = await auth.post("usuarios/login", data);

      if (response.data.erro) {
        setErro("Usuário ou senha inválido");
        setLoanding(false);
      } else {
        let Retorno = response.data;
        if(Retorno.empresas.length === 1) {
          await setID(Retorno.token, parseInt(Retorno.empresas[0].id))

          sessionStorage.setItem("@IdEmpresa", parseInt(Retorno.empresas[0].id));
          sessionStorage.setItem("@CnpjEmpresa", Retorno.empresas[0].cnpj);
          sessionStorage.setItem("@razao_social", Retorno.empresas[0].razao_social);
        }else{
          sessionStorage.setItem("@Empresas", JSON.stringify(Retorno.empresas));
        }

        sessionStorage.setItem("@Auth:token", Retorno.token);
        sessionStorage.setItem("@Auth:user", data.email);
        setUser(response.data);
        setLoanding(false);
      }
    } catch (erro) {
      setErro("Ocorreu um erro. Tente novamente");
      setLoanding(false);
      seterrorServer(erro.message);
      console.log(erro);
    }
  };

  async function setID(token, id){
    const configHearders = {
      headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
      },
    };

    await api.get(
        `contratos/empresa?id_rurauth=${id}`, configHearders
    ).then((response) => {
        let id_rurauth = 0
        if(response.data[0]) {
          id_rurauth = response.data[0].id
        }else{
          id_rurauth = response.data.id
        }

        sessionStorage.setItem("@id_rurauth", id_rurauth)
    })
    .catch((error) => {
      console.log(error)
    });
  };
  
  const limpaErro = () => {
    setErro("");
  };

  const singOut = () => {
    sessionStorage.clear();
    localStorage.clear();
    setUser(null);
  };

  useEffect(() => {
    loadingStoreData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        singOut,
        signed: !!user,
        erro,
        limpaErro,
        loanding,
        errorServer
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
