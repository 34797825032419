import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Alert, Box, Divider, LinearProgress, Tab } from "@mui/material";
import {
  MDBBtn
} from 'mdb-react-ui-kit';
import React, { useContext, useEffect, useState } from "react";
import {
  Col, Modal, ModalBody,
  Row
} from "reactstrap";
import ContratosContext from "../../context/ContratosContext";
import api from "../../services/api";
import TableFinan from "../Tables/TableFinan";
import TableNota from "../Tables/TableNota";
import "./Details.css";

const ModalDet = (props) => {
  const [Load, setLoad] = useState(false);
  const [Abre, setAbre] = useState(false);
  const [Notas, setNotas] = useState(false);
  const [Finan, setFinan] = useState(false);
  const [Sev, setSev] = useState("warning");
  const [Retorno, setRetorno] = useState(null);
  const [Dados, setDados] = useState([]);

  const [RetornoN, setRetornoN] = useState(null);
  const [SevN, setSevN] = useState("warning");

  const [RetornoF, setRetornoF] = useState(null);
  const [SevF, setSevF] = useState("warning");
    
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      "Content-Type": "application/json"
    },
  };

  const { Fecha } = useContext(ContratosContext);

  useEffect(() => {
    if (props.abre) {
      setValue("1")
      setRetorno(null);
      setDados(props.Dados);
      getNotas();
      getFinan();
      setAbre(true);
    }

  }, [props.abre]);

  function Close(){
    setAbre(false);
    Fecha();
  }
  
  return (
    <>
      <Modal isOpen={Abre} onClose={() => Close()} tabIndex='-1' centered size="xl">
        <ModalBody style={{ minHeight: 550 }}>
          <Row className='mb-2'>
            <Col md="12" className='sTitle'>
              <Box className='titleModal' style={{ fontSize: "14pt" }}>Contrato nº {lpad(Dados.id)}</Box>
              <MDBBtn className='btn-close' color='none' onClick={() => Close()}></MDBBtn>
            </Col>
            <Divider style={{borderColor: "#41895E", borderWidth: "medium"}} />
          </Row>

          { Dados.id ? (
            <>
                <Row className='mb-2'>
                  <Col lg="2" md="4" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Data
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.data_contrato }
                    </Col>
                  </Col>

                  <Col lg="2" md="4" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Nº Contrato
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.numero }
                    </Col>
                  </Col>

                  <Col lg="2" md="4" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Safra
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.safra }
                    </Col>
                  </Col>

                  <Col lg="2" md="4" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Moeda
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.moeda }
                    </Col>
                  </Col>

                  <Col md="4">
                    <Col md="12" className='mb-2 title'>
                      Cidade / UF
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.cidade +" / "+Dados.uf }
                    </Col>
                  </Col>
                </Row>
                
                <Row className='mb-2'>
                  <Col md="12">
                    <Row>
                      <Col md="12" className='mb-2 title'> 
                        Cliente
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>

                      <Col lg="7" md="12" xs="auto" className="subtitle">
                        { Dados.cliente }
                      </Col>

                      <Col lg="3" md="12" xs="auto" className="subtitle">
                        { Dados.cnpj }
                      </Col>

                      <Col lg="2" md="12" xs="auto" className="subtitle" >
                        { "IE: " + Dados.ie }
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className='mb-2'>
                  <Col lg="4" md="12" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Produto
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.produto }
                    </Col>
                  </Col>

                  <Col lg="4" md="12" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Quantidade
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.quantidade_um }
                    </Col>
                  </Col>

                  <Col lg="4" md="12" xs="auto">
                    <Col md="12" className='mb-2 title'>
                      Quantidade Carregada
                      <Divider style={{borderColor: "#41895E"}} />
                    </Col>
                    <Col md="12" className="subtitle">
                      { Dados.qtd_carregado +" "+ Dados.um } 
                    </Col>
                  </Col>
                </Row>

                { Dados.armazenador ? (
                  <Row className='mb-2'>
                    <Col md="12">
                      <Row>
                        <Col md="12" className='mb-2 title'>
                          Armazenador
                          <Divider style={{borderColor: "#41895E"}} />
                        </Col>

                        <Col md="8" className="subtitle">
                          { Dados.armazenador }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ) : null }
              
              <Divider style={{borderColor: "#41895E", borderWidth: "2px"}} />
              
              <TabContext value={value}>
                <TabList onChange={handleChange} centered>
                  <Tab label="Notas" value="1" />
                  <Tab label="Financeiro" value="2" />
                </TabList>

                <TabPanel value="1" style={{ padding: "10px" }}>
                  <Row className='mb-2'>
                    <ContratosContext.Provider value={{ Close }}>
                      <Col md="12"><TableNota Notas={Notas} moeda={Dados.moeda} um={Dados.um} /></Col>
                    </ContratosContext.Provider>
                  </Row>
                </TabPanel>

                <TabPanel value="2">
                  <Row className='mb-2'>
                    <Col lg="3" md="12" xs="auto">
                      <Col md="12" className='mb-2 title'>
                        Unit.
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>
                      <Col md="12" className="subtitle">
                        { Dados.unit}
                      </Col>
                    </Col>

                    <Col lg="3" md="12" xs="auto">
                      <Col md="12" className='mb-2 title'> 
                        Valor Total
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>
                      <Col md="12" className="subtitle">
                        { Dados.Vtotal}
                      </Col>
                    </Col>

                    <Col lg="3" md="12" xs="auto">
                      <Col md="12" className='mb-2 title'>
                        Créditos
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>
                      <Col md="12" className="subtitle">
                        { "R$ " + fDecimal(Dados.financeiro_credito) }
                      </Col>
                    </Col>

                    <Col lg="3" md="12" xs="auto">
                      <Col md="12" className='mb-2 title'>
                        Débitos
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>
                      <Col md="12" className="subtitle">
                        { "R$ " + fDecimal(Dados.financeiro_debito) }
                      </Col>
                    </Col>
                  </Row>

                  <Row className='mb-2'>
                    <Col md="12">
                      <Col md="12" className='mb-2 title'>
                        Financeiros
                        <Divider style={{borderColor: "#41895E"}} />
                      </Col>

                      <ContratosContext.Provider value={{ Close }}>
                        <Col md="12"><TableFinan Finan={Finan} moeda={Dados.moeda} um={Dados.um} /></Col>
                      </ContratosContext.Provider>
                    </Col>
                  </Row>
                </TabPanel>
              </TabContext>
            </>
          ): null}

          <Row className='mb-2'>
            { Retorno ? (
              <Col md="12" className='mb-2'>
                <Alert severity={Sev}>{Retorno}</Alert>
              </Col>
            ) : null }
            
            { Load ? (
              <Col md="12" className='mb-2'><LinearProgress /></Col>
            ) : null }
          </Row>
        </ModalBody>
      </Modal>
    </>
  );

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  async function getNotas(){
    setNotas([])
    setRetornoN(null);
    await api.get(
      `contratos/${props.Dados.cod_contrato}/notas?id_empresa=${parseInt(sessionStorage.getItem("@IdEmpresa"))}`, configHearders
    ).then((response) => {
      setNotas(response.data)
    })
    .catch((error) => {
      console.log(error)
      setRetornoN("Erro ao trazer notas.");
      setSevN("error") 
    });

    setLoad(false);
  }

  async function getFinan(){
    setFinan([])
    setRetornoF(null);
    await api.get(
      `contratos/${props.Dados.cod_contrato}/financeiros?id_empresa=${parseInt(sessionStorage.getItem("@IdEmpresa"))}`, configHearders
    ).then((response) => {
      setFinan(response.data)
    })
    .catch((error) => {
      console.log(error)
      setRetornoF("Erro ao trazer financeiros.");
      setSevF("error")
    });

    setLoad(false);
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }
};

export default ModalDet;