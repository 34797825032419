import axios from "axios";

import { API_KEY, URL_API, URL_AUTH } from "./URLS";
let token = sessionStorage.getItem("@Auth:token");
const api = new axios.create({
  baseURL: URL_API,
});

async function refreshToken() {
  let dados = {
      email: sessionStorage.getItem("@Auth:user")
  };

  await axios.post(
      `${URL_AUTH}usuarios/sessao/renovar`, dados, {
      headers: {
        api_key: API_KEY,
        Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`
      },
  })
  .then((response) => {
    sessionStorage.setItem("@Auth:token", response.data.token);
    token = sessionStorage.getItem("@Auth:token");
    //console.log(response)
    return response.data.token;
  })
  .catch((error) => {
    console.log(error);
    sessionStorage.clear();
    localStorage.clear();
    // Se houver um erro ao renovar o token, você pode lidar com isso aqui
    window.location.href = "/"
  });
}

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      console.log('401')
      refreshToken()
     /* sessionStorage.clear();
      localStorage.clear();
      // Se houver um erro ao renovar o token, você pode lidar com isso aqui
      window.location("/")*/
    .then(newToken => {
      console.log(newToken)
      // Reenvia a solicitação original com os novos cabeçalhos
      return api(error.config);
    })
    .catch(refreshError => {
      console.log('catch')
      console.log(refreshError)
      // Se houver um erro ao renovar o token, você pode lidar com isso aqui
      //console.log(refreshError);
      sessionStorage.clear();
      localStorage.clear();
      // Se houver um erro ao renovar o token, você pode lidar com isso aqui
      window.location.href = "/"
      return Promise.reject(refreshError);
    });
  }else{
    console.log(error.response)
  }
    return Promise.reject(error);
  }
);

api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default api;