import SyncIcon from '@mui/icons-material/Sync';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Alert, Button, IconButton, LinearProgress, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from "@mui/material";
import Paper from '@mui/material/Paper';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  MDBCol,
  MDBRow
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import TableFilter from 'react-table-filter';
import 'react-table-filter/lib/styles.css';
import ContratosContext from "../../context/ContratosContext";
import api from "../../services/api";
import GridCards from "../GridCardsHome/GridCardsHome";
import Detail from "../Modal/Detail";
import './Table.css';

const TableContratos = (props) => {
  const [Load, setLoad] = useState(false);
  const [Contratos, setContratos] = useState([]);
  const [UContratos, setUContratos] = useState([]);
  const [Retorno, setRetorno] = useState(null);
  const [Sev, setSev] = useState("warning");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [Modal, setModal] = useState(false);
  const [DadosContrato, setDadosContrato] = useState(null);
  const [dTotalizador, setdTotalizador] = useState([]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - Contratos.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const configHearders = {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("@Auth:token")}`,
      "Content-Type": "application/json"
    },
  };

  function _filterUpdated(newData, filterConfiguration){
    if(newData.length === 0){
      setContratos(UContratos)
    }else{
      setContratos(newData);
      Totalizador(newData);
      setUContratos(newData);
    }
  }

  useEffect(() => {
    setLoad(true);
    GetContratos();
  }, []);

  return (
    <>
      <ContratosContext.Provider value={{ Fecha }}>
        <Detail abre={Modal} Dados={DadosContrato} />
      </ContratosContext.Provider>
      
      <MDBRow className='mb-2'>
        { Retorno ? (
          <MDBCol md="12" className='mb-2'>
            <Alert severity={Sev}>{Retorno}</Alert>
          </MDBCol>
        ) : null }
        
        { Load ? (
          <MDBCol md="12" className='mb-2'><LinearProgress /></MDBCol>
        ) : null }
        <MDBCol md="12" className='mb-2' style={{ display: "flex", justifyContent: "flex-end"}}>
          <Button variant="contained" size="small" color="success" onClick={() => sinc()}>
            <SyncIcon /> Atualizar
          </Button>
        </MDBCol>
      </MDBRow>
        
      { Contratos.length > 0 ? (
        <>
          <TableContainer component={Paper} className="TbContratos" style={{ minHeight: 230 }}>
            <Table aria-label="custom pagination table" style={{ fontSize: 10 }}>
              <TableHead>
                <TableFilter
                  rows={Contratos}
                  onFilterUpdate={_filterUpdated}
                  resetFilters={"false"}
                >
                  <TableCell size={"small"} component={"th"}></TableCell>
                  <TableCell id="numero" size={"small"} component={"th"} key={"numero"} filterkey="numero" showsearch={"true"}>{"N° "}</TableCell>
                  <TableCell id="ano" size={"small"} component={"th"} key={"ano"} filterkey="ano" showsearch={"true"}> Ano </TableCell>
                  <TableCell id="data_contrato" size={"small"} component={"th"} key={"data_contrato"} filterkey="data_contrato" showsearch={"true"}> Data </TableCell>
                  <TableCell id="safra" size={"small"} component={"th"} key={"safra"} filterkey="safra" showsearch={"true"}> Safra </TableCell>
                  <TableCell id="cliente" size={"small"} component={"th"} key={"cliente"} filterkey="cliente" showsearch={"true"}> Cliente </TableCell>
                  <TableCell id="produto" size={"small"} component={"th"} key={"produto"} filterkey="produto" showsearch={"true"} alignleft={"true"}> Produto </TableCell>
                  <TableCell id="quantidade_um" size={"small"} component={"th"} align="right" key={"quantidade_um"} filterkey="quantidade_um" showsearch={"true"} alignleft={"true"}> Quantidade </TableCell>
                  <TableCell id="unit" size={"small"} component={"th"} align="right" key={"unit"} filterkey="unit" showsearch={"true"} alignleft={"true"}> Unit. </TableCell>
                  <TableCell id="Vtotal" size={"small"} component={"th"} align="right" key={"Vtotal"} filterkey="Vtotal" showsearch={"true"} alignleft={"true"}> Vlr Total. </TableCell>
                  <TableCell id="qtd_carregado" size={"small"} component={"th"} align="right" key={"qtd_carregado"} filterkey="qtd_carregado" showsearch={"true"} alignleft={"true"}> Carregado (KG) </TableCell>
                  <TableCell id="financeiro_credito" size={"small"} component={"th"} align="right" key={"financeiro_credito_f"} filterkey="financeiro_credito_f" showsearch={"true"} alignleft={"true"}> Crédito </TableCell>
                  <TableCell id="financeiro_debito" size={"small"} component={"th"} align="right" key={"financeiro_debito_f"} filterkey="financeiro_debito_f" showsearch={"true"} alignleft={"true"}> Débito </TableCell>
                  <TableCell id="saldo" size={"small"} component={"th"} align="right" key={"saldo_f"} filterkey="saldo_f" showsearch={"true"} alignleft={"true"}> Saldo </TableCell>
                  <TableCell id="armazenador" size={"small"} component={"th"} key={"armazenador"} filterkey="armazenador" showsearch={"true"} alignleft={"true"}> Armazenador </TableCell>
                </TableFilter>
              </TableHead>
              
              <TableBody id="bTable">
                {(rowsPerPage > 0 ? Contratos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : Contratos ).map((row, i) => (
                  <TableRow key={i} id={"b"+i}>
                    <TableCell >
                      <IconButton aria-label="delete" size="small" onClick={(e) => Detalhar(row)}>
                        <ZoomInIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell >{ row.numero }</TableCell>
                    <TableCell >{ row.ano }</TableCell>
                    <TableCell >{row.data_contrato}</TableCell>
                    <TableCell >{row.safra}</TableCell>
                    <TableCell >{row.cliente}</TableCell>
                    <TableCell >{row.produto}</TableCell>
                    <TableCell align="right" >{row.quantidade_um}</TableCell>
                    <TableCell align="right" >{row.unit}</TableCell>
                    <TableCell align="right" >{row.Vtotal}</TableCell>
                    <TableCell align="right" >{row.qtd_carregado}</TableCell>
                    <TableCell align="right" >{row.financeiro_credito_f}</TableCell>
                    <TableCell align="right" >{row.financeiro_debito_f}</TableCell>
                    <TableCell align="right" >{row.saldo_f}</TableCell>
                    <TableCell >{row.armazenador}</TableCell>
                  </TableRow>
                ))}

                {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}*/}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} className="TbContratos">
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  
                  count={Contratos.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={
                    ({ from, to, count }) => {
                      return 'Mostrando ' + from + '-' + to + ' de ' + count
                    }
                  }
                  labelRowsPerPage={ "Registros por página" }
                />
              </TableRow>
            </TableFooter>
          </TableContainer>
          
          <ContratosContext.Provider value={{ Totalizador }}>
            <GridCards dTotalizador={dTotalizador} />
          </ContratosContext.Provider>
        </>
      ) : (
        <>
          { !Retorno ? (
            <>
              <TableContainer component={Paper} className="TbContratos">
                <Table aria-label="custom pagination table" style={{ fontSize: 21 }}>
                  <TableRow style={{ height: 53 * emptyRows }} key={0}>
                    <TableCell align="center" colSpan={12}>{"Sem registros."}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </>
          ) : null }
        </>
      )}
    </>
  );

  function sinc(){
    setContratos([])
    setLoad(true);
    GetContratos();
  }

  async function GetContratos(){
    setRetorno(null);
    await api.get(
      `contratos?id_empresa=`+parseInt(`${sessionStorage.getItem("@id_rurauth")}`), configHearders
    ).then((response) => {
      let Cont = response.data;
      for (let i = 0; i < Cont.length; i++) {
        Cont[i].quantidade_um = fDecimal(Cont[i].quantidade) + " " + Cont[i].um;
        Cont[i].saldo_f = "R$ "+fDecimal(Cont[i].saldo);
        Cont[i].financeiro_credito_f = "R$ "+fDecimal(Cont[i].financeiro_credito);
        Cont[i].financeiro_debito_f = "R$ "+fDecimal(Cont[i].financeiro_debito);
        Cont[i].data_contrato = new Date(new Date(Cont[i].data).setHours(24)).toLocaleDateString('pt-BR');
        Cont[i].qtd_carregado = fDecimal(Cont[i].carregado);
        Cont[i].unit = "R$ "+fDecimal(Cont[i].unitario);
        Cont[i].Vtotal = "R$ "+fDecimal(Cont[i].total);
        Cont[i].safraa = Cont[i].safra+i;
      }

      setContratos(Cont);
      setUContratos(Cont);
      Totalizador(Cont);
    })
    .catch((error) => {
      console.log(error)
      setContratos([]);
      setRetorno("Erro ao trazer contratos.");
      setSev("error");

      const timer = setTimeout(() => {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = "/"
      }, 1500);
      return () => clearTimeout(timer);
    });

    setLoad(false);
  }

  function Detalhar(dados){
    setDadosContrato(dados)
    setModal(true)
  }

  function Totalizador(contratos){
    /*var elementos = document.querySelectorAll('#bTable');
    if(elementos){
      elementos = document.querySelectorAll('#bTable tr');
      let td = [];
      let qtd = 0;
      let vlrTotal = 0;
      let carregado = 0;
      let credito = 0;
      let debito = 0;
      let saldo = 0;

      for (let i = 0; i < elementos.length; i++) {
        td = elementos[i].closest('tr').children
        qtd = qtd + parseFloat(td[7].textContent.split(" KG", 3)[0].replace(/[.]/g,""));
        vlrTotal = vlrTotal + parseFloat(td[9].textContent.split("R$ ", 3)[1].replace(/[.]/g,""));
        carregado = carregado + parseFloat(td[10].textContent.replace(/[.]/g,""));
        credito = credito + parseFloat(td[11].textContent.split("R$ ", 3)[1].replace(/[.]/g,""));
        debito = debito + parseFloat(td[12].textContent.split("R$ ", 3)[1].replace(/[.]/g,""));
        saldo = saldo + parseFloat(td[13].textContent.split("R$ ", 3)[1].replace(/[.]/g,""));
      };

      let a = {
        vQuantidade: fDecimal(qtd) + " KG",
        vTotal: fDecimal(vlrTotal),
        vCarregado: fDecimal(carregado) + " KG",
        vCredito: fDecimal(credito),
        vDebito: fDecimal(debito),
        vSaldo: fDecimal(saldo),
      }

      setdTotalizador(
        JSON.stringify(a)
      )
    }*/

    let qtd = 0;
    let vlrTotal = 0;
    let carregado = 0;
    let credito = 0;
    let debito = 0;
    let saldo = 0;

    for (let i = 0; i < contratos.length; i++) {
      qtd = qtd + contratos[i].quantidade;
      vlrTotal = vlrTotal + contratos[i].total
      carregado = carregado + contratos[i].carregado
      credito = credito + contratos[i].financeiro_credito
      debito = debito + contratos[i].financeiro_debito
      saldo = saldo + contratos[i].saldo
    }

    setdTotalizador(
      JSON.stringify({
        vQuantidade: fDecimal(qtd) + " KG",
        vTotal: fDecimal(vlrTotal),
        vCarregado: fDecimal(carregado) + " KG",
        vCredito: fDecimal(credito),
        vDebito: fDecimal(debito),
        vSaldo: fDecimal(saldo) + " KG",
        vSaldoF: fDecimal(credito - debito),
      })
    )
  }

  function fDecimal(Valor) {
    return new Intl.NumberFormat("pt-BR", { currency: "BRL", minimumFractionDigits: 2 }).format(Valor);
  }

  function lpad(num) {
    let size = 6;
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  function Fecha(){
    setModal(false)
  }
};

export default TableContratos;